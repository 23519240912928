import { Pipe, PipeTransform } from '@angular/core';
declare var DOMPurify: any
@Pipe({
	name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		return value.replace(/<.*?>/g, '').replace(/&nbsp;/g, ' ') + '\n\n';
	}
}
