export const countries = [
	{ code: 'AL', name: 'Albania' },
	{ code: 'AR', name: 'Argentina' },
	{ code: 'BO', name: 'Bolivia' },
	{ code: 'BW', name: 'Botswana' },
	{ code: 'BR', name: 'Brazil' },
	{ code: 'CL', name: 'Chile' },
	{ code: 'CR', name: 'Costa Rica' },
	{ code: 'HR', name: 'Croatia' },
	{ code: 'CZ', name: 'Czech Republic' },
	{ code: 'EC', name: 'Ecuador' },
	{ code: 'DE', name: 'Germany' },
	{ code: 'GR', name: 'Greece' },
	{ code: 'IL', name: 'Israel' },
	{ code: 'IT', name: 'Italy' },
	{ code: 'JM', name: 'Jamaica' },
	{ code: 'LS', name: 'Lesotho' },
	{ code: 'LU', name: 'Luxembourg' },
	{ code: 'MT', name: 'Malta' },
	{ code: 'MX', name: 'Mexico' },
	{ code: 'MA', name: 'Morocco' },
	{ code: 'MK', name: 'North Macedonia' },
	{ code: 'PE', name: 'Peru' },
	{ code: 'PL', name: 'Poland' },
	{ code: 'PT', name: 'Portugal' },
	{ code: 'RS', name: 'Serbia' },
	{ code: 'ZA', name: 'South Africa' },
	{ code: 'ES', name: 'Spain' },
	{ code: 'UA', name: 'Ukraine' },
	{ code: 'GB', name: 'United Kingdom' },
	{ code: 'US', name: 'United States' },
	{ code: 'UY', name: 'Uruguay' },
	{ code: 'ZW', name: 'Zimbabwe' }
];
