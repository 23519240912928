import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stripText',
})
export class StripTextPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		return value.substring(0, 150) + '...';
	}

}
