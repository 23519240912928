import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { API } from 'src/app/consts/Api';
import { StorageKeys } from 'src/app/consts/storage-keys';
import { HttpService } from 'src/app/services/http/http.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { AgeRestrictionComponent } from '../age-restriction/age-restriction.component';
import { environment } from 'src/environments/environment';
import { ForumRef } from 'src/app/enums/forum-from-ref.enum';
import { Auth } from '@angular/fire/auth';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	private auth = inject(Auth);

	isLogin: boolean = false;
	@Input('brands') brands: any;

	userPersonalDetails: any;
	cartItemsCount = 0;
	myAccountItems = [
		{
			label: 'Profile & Settings',
			icon: 'person',
			routerLink: '/settings',
		},
		{
			label: 'Orders',
			icon: 'history',
			routerLink: '/order-history',
		},
		{
			label: 'Sign Out',
			icon: 'logout',
			command: () => {
				this.signOut();
			},
		},
	];
	readonly imageUrl = environment.imageUrl;

	constructor(public userService: UserService, private router: Router) { }

	get ForumRef() {
		return ForumRef;
	}

	ngOnInit(): void {
		this.isLogin = this.userService.isLogIn;
		if (this.userService.isLogIn) {
			this.userPersonalDetails = this.userService.userData.user_data;
		}
		this.userService.onUserLoginStatusChange.subscribe((userData: any) => {
			if (userData) {
				this.isLogin = true;
				this.userPersonalDetails = userData.user_data;
			}
		});

		this.userService.onCartUpdate.subscribe(() => {
			this.refreshCartCount();
		});
		this.refreshCartCount();
	}

	ngAfterViewInit() { }

	openSideMenu() {
		document
			.querySelector('nav#mobile-nav')
			?.classList.add('mobile-nav-active');
		document
			.querySelector('nav#mobile-nav')
			?.classList.remove('mobile-nav-inactive');
	}

	closeSideMenu() {
		document
			.querySelector('nav#mobile-nav')
			?.classList.add('mobile-nav-inactive');
		document
			.querySelector('nav#mobile-nav')
			?.classList.remove('mobile-nav-active');
	}

	signOut() {
		this.isLogin = false;
		this.userService.isLogIn = false;
		this.userService.userData = null;
		localStorage.clear();
		sessionStorage.clear();
		this.refreshCartCount();
		this.auth.signOut();
		this.router.navigate(['/'], {
			replaceUrl: true,
		});
	}

	refreshCartCount() {
		const _cart = localStorage.getItem(StorageKeys.CART);
		if (_cart) {
			const cartDataParsed = JSON.parse(_cart);
			this.cartItemsCount = cartDataParsed.length;
		} else {
			this.cartItemsCount = 0;
		}
	}
}
