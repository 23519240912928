import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysAgoPipe } from './days-ago/days-ago.pipe';
import { CommaSplitterPipe } from './comma-splitter/comma-splitter.pipe';
import { RemoveCharactersPipe } from './remove-characters/remove-characters.pipe';
import { CalendarTimePipe } from './calendar-time/calendar-time.pipe';
import { BadgePipe } from './badge/badge.pipe';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html.pipe';
import { StripHtmlPipe } from './strip-html/strip-html.pipe';
import { StripTextPipe } from './strip-text/strip-text.pipe';

@NgModule({
	declarations: [
		DaysAgoPipe,
		CommaSplitterPipe,
		RemoveCharactersPipe,
		CalendarTimePipe,
		BadgePipe,
		SanitizeHtmlPipe,
		StripHtmlPipe,
		StripTextPipe
	],
	imports: [CommonModule],
	exports: [
		DaysAgoPipe,
		CommaSplitterPipe,
		RemoveCharactersPipe,
		CalendarTimePipe,
		BadgePipe,
		SanitizeHtmlPipe,
		StripHtmlPipe,
		StripTextPipe
	],
})
export class PipesModule { }
